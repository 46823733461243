html,
body {
	background: #4B4B55;
	color: white;
	height: 100%;
	width: 100%;
}

#root {
	margin: 0 auto;
	display: flex;
	flex-flow: column;
	height: 100%;
	max-width: 440px !important;
}

.footer {
	padding: 10px;
	text-align: center;
	display: block !important;
	bottom: 0px;
	left: 0px;
	right: 0px;
	margin: 0 auto;
	max-width: 440px !important;
	background: #430e50;
	width: 100%;
}

.footer a {
	color: white;
}

@media only screen and (min-width: 420px) {
	.footer {
		background: none !important;
		width: auto;
	}
}

.footer p {
	margin: 0;
	padding: 0;
}

.container {
	height: 80vh;
	overflow-y: scroll;
	-ms-overflow-style: none !important;
	/* IE and Edge */
	scrollbar-width: none !important;
	/* Firefox */
	flex: 1;
}

.container::-webkit-scrollbar {
	width: 0px;
	background: transparent !important;
	/* make scrollbar transparent */
}

.example {}

	@media only screen and (min-width: 420px) {
		.container {
			height: auto;
		}
	}

	.logo {
		display: block;
		margin: 0px auto 0px auto;
		padding: 5px 15px;
		width: 100%;
	}

	.logo img {
		display: block;
		margin: 0px auto 0px auto;
		max-width: 95%;
		max-height: 68px;
	}

	@media only screen and (max-width: 420px) {
		.logo img {
			max-width: 50%;
		}
	}

	.singles .editHelpMsg{
		display: none;
	}

	.editHelpMsg {
		position: absolute;
		width: 100%;
		font-size: 12px;
		top: 10px;
	}

	.editHelpMsg .inner{
		//text-align: center;
		background: rgba(0, 0, 0, 0.8);
		display: block;
		margin: 0 auto;
		max-width: 370px;
		color: white;
		border-radius: 10px;
		padding: 10px;
	}

	.editHelpMsg strong{
		display: block;
	}

	.editHelpMsg .emoji{
		margin-right: 6px;
	}

	.container,
	.topNav {
		display: block!important;
		margin: 0 auto !important;
		min-width: 320px;
	}

	.topNav {
		text-align: center;
		display: block;
		margin: 0;
		padding: 0 !important;
	}

	.topNav li {
		display: inline;
		list-style-type: none;
	}

	*:focus {
		outline: none;
	}

	.topNav button {
		position: relative;
		width: 90px;
		height: 35px;
		margin: 5px 5px 0px 5px;
		padding: 5px;
		border-radius: 5px 5px 0px 0px;
	}

	.topNav button.profile {
		background: #ce4a6f !important;
		color: white;
	}

	.topNav button.singles {
		background: #68167c !important;
		color: white;
	}

	.topNav button.matches {
		background: #79519A !important;
		color: white;
	}

	.topNav button.chat {
		background: #aa45ad !important;
		color: white;
	}

	.topNav button:hover {
		color: white !important;
	}

	.card {
		margin: 0 10px;
		border: 0;
		border-radius: 10px;
		box-shadow: -2px 1px 20px -2px rgba(0, 0, 0, 0.48);
		background: white;
		color: black;
	}

	.cardInner {
		padding: 0px 20px 0px 25px;
	}

	.cardInner p {
		line-height: 18px;
	}

	.cardInner p,
	.cardInner h2 {
		position: relative;
	}

	.profile .cardInner p,
	.profile .cardInner h2 {
		border: 3px dashed rgba(170, 69, 173, 0.35);
		padding: 5px 20px 5px 5px;
		margin: 10px -21px 8px -12px;
		right: 7px;
	}

	@media only screen and (max-width: 400px) {
		.topNav button {
			width: 70px;
			font-size: 12px;
		}
	}

	@media only screen and (max-width: 420px) {
		.profile .cardInner p,
		.profile .cardInner h2 {
			padding: 2px 20px 2px 5px;
			margin: 10px -21px 8px -12px;
			right: 7px;
		}
	}

	.myProfileHeading {
		padding-top: 5px;
		font-size: 24px;
	}

	h2 {
		padding-top: 0px !important;
	}

	.container.empty-state{
		color: white;
		position: relative;
		padding: 0 !important;


		color: white;
		text-align:center;
		padding: 55px;
	}

	.welcome{
		background: black !important;
	}

	.on-top-of-scrolling-bg{
		top: 0px;
		margin: 0;
		height: 100%;
		width: 100%;
		overflow-y: scroll !important;
		padding: 10px;
		display: block;
		position: absolute;

	}

	.container.profile {
		padding-top: 8px;
		display: block;
		background: #ce4a6f !important;
		color: white;
	}

	.container.singles {
		background: #68167c !important;
		color: white;
	}

	.container.matches {
		background: #79519A !important;
		color: white;
	}

	.container.chat {
		background: #aa45ad !important;
		color: white;

	}





	.empty-state .candy-button{
		background:#ce4a6f;
		border: 2px solid #f45e75;
	}

	.empty-state .candy-button:hover{
		background:#f45e75;
		border: 2px solid #f45e75;

	}

	.empty-state ol{
		text-align:left;
		font-size: 20px;
	}



	.container.empty-state {
		overflow: hidden !important;
		padding-top:20px;
	}
	.container.empty-state h1{
		font-size: 28px;
	}

	.empty-state p{
		font-size: 17px;
	}

	.scrolling-bg {
		opacity: 0.1;
		height: 500px;
		width: 1280px;
		animation: slide 30s linear infinite;
	}

	@keyframes slide{
		0%{
			transform: translate3d(0, 0, 0);
		}
		100%{
			transform: translate3d(-1280px, 0, 0);
		}
	}





	.empty-state-image {
		max-width: 250px;
	}



	@media only screen and (min-width: 420px) {
		.footer {
			border-radius: 0 0 10px 10px;
		}
		.container.welcome,
		.container.profile,
		.container.singles,
		.container.chat,
		.container.matches {
			border-radius: 20px;
		}
	}

	.candy-button {
		margin: 5px 10px;
		outline: none !important;
		font: 20px sans-serif;
		font-weight: bold;
		user-select: none;
		text-decoration: none;
		padding: 6px 12px;
		color: white;
		border: none;
		border-radius: 50px;
		border: 2px solid #f573f9;
		box-shadow: -1px 5px 0px -2px rgba(0, 0, 0, 1);
		background: #aa45ad;
	}

	.candy-button:hover {
		background: #f573f9;
		transition: all 0.2s ease-in-out;
	}

	.profilePic {
		border-radius: 10px 10px 0 0;
		margin: 0px !important;
		width: 100%;
		display: block;
		position: relative;
	}

	@media only screen and (min-width: 420px) {
		.profilePic {
			height: 340px;
		}
	}

	@media only screen and (max-width: 419px) {
		.profilePic {
			height: 320px;
		}
	}

	.profilePic img {
		border-radius: 10px 10px 0 0;
		position: absolute;
		max-width: 100%;
	}

	.picButtons {
		bottom: 16px;
		right: 5px;
		z-index: 100;
		position: absolute;
	}

	.hoverButton {
		position: absolute;
		top: -20px;
		right: -25px;
	}

	.deathDate .hoverButton {
		top: -30px;
	}

	.small-card {
		position: relative;
		width: 100%;
		height: 100px;
		margin: 10px 10px 2px 0px;
		outline: none !important;
		font: 20px sans-serif;
		font-weight: bold;
		user-select: none;
		text-decoration: none;
		padding: 8px 12px;
		color: white;
		border: none;
		border-radius: 10px;
		border: 2px solid #9d3bb5;
		box-shadow: -1px 5px 0px -2px rgba(0, 0, 0, 1);
		background: #68167c;
	}

	.small-card:hover {
		background: #9d3bb5;
		transition: all 0.2s ease-in-out;
	}

	.col-3,
	.col-9 {
		//border: 1px solid red !important;
	}

	.profile-pic-image {
		display: block;
		position: relative;
	}

	.profile-pic-image img {
		position: absolute;
	}

	.chat .profile-pic-image img {
		border-radius: 100%;
		border: 2px solid white;
	}



	.small-card .profile-pic-image img {
		height: 75px;
		width: 75px;
		filter: none;

	}

	.small-card .backgroundPic{
		border-radius: 100%;
		border: 2px solid white;


	}

	.small-card .name {
		font-size: 20px;
		margin-top: 24px;
	}

	.chat-window .profile-pic-image img {
		max-width: 75px;
		max-height: 75px;
	}

	.chat-window .header .name {
		padding-top: 5px;
	}

	.chat-window .header .name span {
		font-size: 15px;
		display: block;
	}

	.chat-window .candy-button {
		padding: 6px 26px;
		margin-bottom: 10px;
	}

	* {
		box-sizing: border-box;
	}

	.chat-window {
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.chat-window .header {
		padding-top: 10px;
		height: 75px;
	}

	.chat-messages-container {
		flex: 1;
		overflow-y: scroll;
		background: white;
		border-radius: 20px;
		color: #403c3c;
		padding-right: 25px;
		min-height: 200px;
	}

	.chat-message {
		max-width: 75%;
		border-radius: 20px;
		padding: 8px 15px;
		margin-top: 5px;
		margin-bottom: 5px;
		display: block;
		position: relative;
	}

	.chat-message-other {
		background: #eee;
	}

	.chat-message-me {
		right: -25%;
		color: white;
		background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
	}

	.chat-window .keyboard {
		margin: 10px 0 17px 0;
		padding-top: 0px;
		min-width: 290px;
	}

	.text-row .candy-button {
		margin-top: 10px;
		padding: 5px;
		height: 75px;
		width: 75px;
	}

	.text-row {
		background: white;
		border-radius: 20px 20px 0 0;
	}

	.text-row textarea {
		vertical-align: middle;
		border: 0;
		border-radius: 20px 0 0 0;
		resize: none;
		scrollbar-width: none !important;
		background: white !important;
	}

	.actual-keyboard {
		border-radius: 0 0 20px 20px;
		background: #cbced6;
		margin: 0;
		padding: 0 !important;
	}

	.actual-keyboard .col-2 {
		padding: 0;
	}

	.keys {
		max-height: 100px;
		margin: 0;
	}

	.row.emojis {
		margin-right: 2px;
		overflow-y: scroll;
		max-height: 58px;
	}

	.keys .candy-button {
		margin: 5px;
		padding: 5px 8px;
		font-size: 14px;
	}

	.actual-keyboard .candy-button {
		width: 120px;
		color: #373539;
		margin: 2px 2px;
		border-radius: 8px;
		background: white;
		border-color: #a2abb5;
		box-shadow: none;
		background: #d8dce7;
		background: linear-gradient(0deg, #d8dce7 0%, white 100%);
	}

	.space {
		width: 175px !important;
	}

	.actual-keyboard .candy-button:hover {
		border-color: #94999f;
		background: #cbced6;
		background: linear-gradient(0deg, #cbced6 0%, white 100%);
	}

	.emojis .candy-button {
		max-width: 38px !important;
		margin: 1px 2px;
	}

	.candy-button.keyboard-mode {
		margin: 0;
		padding: 4px;
		height: 100px;
		width: 58px;
		border-radius: 0;
		font-size: 14px;
		background: #a2abb5;
		background: linear-gradient(0deg, #a2abb5 0%, #cbced6 100%);
		border-color: #899199;
		border-radius: 0 0 0 20px;
	}

	.candy-button.keyboard-mode:hover {
		border-color: #94999f;
		background: linear-gradient(0deg, #616469 0%, #cbced6 100%);
	}

	.modeEmoji {
		display: block;
		margin-top: 4px;
		font-size: 35px;
	}

	.card-swipe {
		position: relative;
	}

	.card-swipe-reject,
	.card-swipe-approve {
		display: block;
		position: absolute;
		top: 0px;
		width: 95%;
		height: 100%;
		font-size: 100px;
		text-align: center;
		padding-top: 100px;
		margin: 0 10px;
		border: 0;
		border-radius: 10px;
	}

	.card-swipe-reject {
		background: #d04258;
	}

	.card-swipe-approve {
		background: #A3C061;
	}

	.container.singles h1 {
		font-size: 24px;
		text-align: center;
		margin-top: 10px;
	}

	.noSinglesLeft {
		margin-top: 10px;
		text-align: center;
	}

	.noSinglesLeft img {
		max-width: 75%;
		border-radius: 100%;
		opacity: 0.5;
		margin: 15px;
	}

	.noSinglesLeft .inviteText {
		display: block;
		font-size: 28px;
		line-height: 32px;
	}

	.emojiOnButton {
		margin-right: 6px !important;
	}

	@media only screen and (max-width: 320px) {
		.chat-messages-container {
			min-height: 144px;
			font-size: 13px;
		}
		.keys {
			padding-left: 31px !important;
		}
		.actual-keyboard .keys .candy-button {
			width: 104px;
			font-size: 11px;
		}
	}

	.chat .characterPic{
		animation: none;
		filter: none;
	}


	.characterPic{
		filter: drop-shadow(5px -5px 0px rgba(0, 0, 0, 0.3));
	}


	[src*="ghost"],
	[src*="chtu"],
	[src*="egg"],
	[src*="mothman"],
	[src*="cluster-junk"],
	[src*="little-ghost"],
	[src*="jersey-devil"],
	[src*="hover"],
	[src*="cute-ghost"],
	[src*="vampire"],
	[src*="beholder"],
	[src*="booty"]
	{

		animation: float 5s ease-in-out infinite alternate;
		transform-origin: 50% 50%;
	}
	@-webkit-keyframes float {
		0% {
			transform: translatey(0px) rotate(0deg);
		}
		50% {

			transform: translatey(-10px) rotate(3deg);
		}
		100% {
			transform: translatey(0px) rotate(0deg);
		}
	}
	@keyframes float {
		0% {
			transform: translatey(0px) rotate(0deg);
		}
		50% {

			transform: translatey(-10px) rotate(3deg);
		}
		100% {
			transform: translatey(0px) rotate(0deg);
		}
	}

	[src*="fresno-nightcrawler"],
	[src*="goss"],
	[src*="bigfoot"],
	[src*="frank"],
	[src*="worm"],
	[src*="firehead"],
	[src*="scoops"],
	[src*="bugbear"],
	[src*="nessie"],
	[src*="headless"],
	[src*="skelly"],
	[src*="dogman"],
	[src*="baxter"],
	[src*="chupa"],
	[src*="hair"],
	[src*="slender"]{
		-webkit-animation:  wobble 3s ease-in-out infinite alternate;
		animation: wobble 3s ease-in-out infinite alternate;
		transform-origin: 50% 100%;
	}

	@-webkit-keyframes  wobble {
		from {
			transform: rotate(4deg) rotateY(10deg);
		}
		to {
			transform: rotate(-2deg) rotateY(-10deg);
		}
	}
	@keyframes wobble {
		from {
			transform: rotate(4deg) rotateY(10deg);
		}
		to {
			transform: rotate(-2deg) rotateY(-10deg);
		}
	}


	/* .pullpal{
	-webkit-animation:grow 3s ease-in-out infinite alternate;
	animation: grow 3s ease-in-out infinite alternate;
	transform-origin: 50% 50%;
}

@keyframes grow {
from {
transform: scale(1.1);
}
to {
transform: scale(1.05);
}
} */


[src*="worm"]{
	transform-origin: 0% 80%;
}


.notification-icon{
	text-align: center;
	padding-top: 1px;
	padding-right: 1px;
	font-size: 13px;
	font-weight: bold;
	display: inline-block;
	width: 25px;
	height: 25px;
	background: #ce4a6f;
	position: absolute;
	border-radius: 100%;
	top: -8px;
	right: -8px;
	border: 3px solid #4B4B55;
}


.closeX{
	position: absolute;
	top: 5px;
	right: 13px;
	border-radius: 100%;
	background: #0000002e;
	color: white;
	border: 0;
}


.hidden{
	display: none;
}
